<template>
  <div>
    <div id="line" style="width: 950px;height:550px;"></div>
    <detail-modal :visible.sync="modalVisible" :detail-data="modalDetailData"></detail-modal>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import {getEngineerDailyInspectionLineTemplate} from "A/patrol";
import DetailModal from "V/engineerInspection/inspectionRecord/DetailModal.vue";
export default {
  name: "PatrolLine",
  components: {DetailModal},
  props: {
    lineData: {
      default: null
    }
  },
  data () {
    return {
      nodeData: [],
      links:[],
      modalVisible: false,
      modalShowType: 'detail',
      modalDetailData: null,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init(){
      getEngineerDailyInspectionLineTemplate({...this.lineData,alldone:'0'}).then(res=>{
        if(res&&res.returncode==='0'){
          this.nodeData = res.nodeData
          this.links = res.links
          this.load()
        }
      })
    },
    // 使用index关联起来
    load () {
      let chartDom = document.getElementById('line')
      let myChart = echarts.init(chartDom)
      let that = this;
      let option
      option = {
        title: {
          text: '巡查线路图'
        },
        tooltip: {},
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            layout: 'none',
            symbolSize: 13,//节点大小为13
            roam: true,
            itemStyle: {//给所有节点的类型一个默认样式，特殊的可在其节点下单独修改样式
              normal: {
                color: "#FFFFFF",//颜色默认白色
                borderColor: "#009900"//边框默认绿色
              }
            },
            label: {//给所有的节点字体一个默认样式
              normal: {
                show: true,//显示
                position: "bottom",//下方显示
                color: "#000",//默认颜色灰色
                fontSize: 12,//字体样式
              },
            },
            lineStyle: {//给所有连线一个默认样式
              normal: {
                width: 2,
                color: "#009900"
              }
            },
            data:this.nodeData,
            links: this.links,
          }
        ]
      },
      // echarts图表的点击事件，可通过param参数确认点击的对象
      // myChart.on("click", function (param) {
      //   if (param.dataType === "node") {
      //     if(param.data.hasOwnProperty("inspection_id")){
      //       getEngineerInspectionListByCondition({inspection_id:param.data.inspection_id}).then(res=>{
      //         if(res&&res.returncode==='0'){
      //           that.modalDetailData=res.item[0]
      //           that.modalShowType="detail"
      //           that.modalVisible=true
      //         }
      //       })
      //     }else{
      //       that.$message.info("该点位还未巡查")
      //     }
      //   } else {
      //     console.log("点击了边", param);
      //   }
      // });
      option && myChart.setOption(option)
    },
  },
}
</script>