<template>
  <div>
    <div class="table-header">
      <h3 class="table-title">人工巡查线路</h3>
      <div class="table-btns">
        <a-radio-group :default-value="0" button-style="solid" v-model="tabsChildKey" @change="onChange">
          <a-radio-button :value="0">
            当前巡查线路
          </a-radio-button>
          <a-radio-button :value="1">
            历史巡查线路
          </a-radio-button>
        </a-radio-group>
        <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
      </div>
    </div>
    <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
      <span slot="patroldailytemplatename" slot-scope="text,record">
        <a-button type="link" @click="rowClick(record)">{{text}}</a-button>
      </span>
      <span slot="starttime" slot-scope="value">
         <a-tooltip :title="value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : ''">
           <span>{{value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : ''}}</span>
         </a-tooltip>
      </span>
      <span slot="endtime" slot-scope="value">
         <a-tooltip :title="value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : ''">
           <span>{{value ? moment(value, 'HH:mm:ss').format('HH:mm:ss') : ''}}</span>
         </a-tooltip>
      </span>
      <span slot="createtime" slot-scope="value">
         <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
           <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
         </a-tooltip>
      </span>
      <span slot="operation" slot-scope="value, record">
        <a-button type="link" @click="rowClick(record)">详情</a-button>
      </span>
    </a-table>
    <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}条/页</span>
      </template>
    </a-pagination>
    <a-modal v-model="templateVisible" :title="title" width="860px">
      <template slot="footer">
        <a-button @click="templateVisible=false">取消</a-button>
        <a-button v-if="templateType==='add'||templateType==='edit'" type="primary" @click="handleOk">提交</a-button>
      </template>
      <a-form-model ref="advancedForm" :model="queryParams" :rules="formRules" layout="inline" class="query-form" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="巡查模板名称" prop="patroldailytemplatename">
          <a-input :disabled="templateType==='detail'" v-model="queryParams.patroldailytemplatename" style="width: 460px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="巡查开始时刻" prop="starttime">
          <a-time-picker :disabled="templateType==='detail'" v-model="queryParams.starttime" format="HH:mm:ss" placeholder="" style="width: 460px"></a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="巡查结束时刻" prop="endtime">
          <a-time-picker :disabled="templateType==='detail'" v-model="queryParams.endtime" format="HH:mm:ss" placeholder="" style="width: 460px"></a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="人工巡查线路" prop="patrolline">
          <div class="role-modal-container">
            <a-transfer :disabled="templateType==='detail'" style="width: 460px" :titles="['待选择', '已选中']" :render="item => item.title" :target-keys="checkedKeys" :data-source="inspectionOptions" @change="inspectionCheckedChange" />
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="lineVisible" width="1000px" height="600px">
      <template slot="footer">
        <a-button @click="lineVisible=false">关闭</a-button>
      </template>
      <patrol-line :key="currentKey" :lineData="detailData"></patrol-line>
    </a-modal>
  </div>
</template>
<script>
import pagination from "@/mixins/pagination";
import moment from "moment";
import {
  addEngineerPatrolDailyTemplate,
  getEngineerPatrolDailyTemplateListByCondition,
  modifyEngineerPatrolDailyTemplate
} from "A/patrol";
import {mapState} from "vuex";
import {getMonitorPointNameList} from "A/monitoring";
import {getItemFromArrayByKey} from "U";
import patrolLine from "./patrolLine.vue";
import {getMachineRoomListByCondition} from "A/facilityandequipment";
export default {
  name: "Index",
  components: {patrolLine},
  mixins: [pagination],
  data() {
    return {
      moment,
      title:'人工巡查线路',
      tableLoading: false,
      tableColumns: [
        {
          title: '巡查线路名称',
          dataIndex: 'patroldailytemplatename',
          key: 'patroldailytemplatename',
          ellipsis: true,
          scopedSlots: { customRender: 'patroldailytemplatename' },
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '巡查最早开始时刻',
          dataIndex: 'starttime',
          key: 'starttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' },
        },
        {
          title: '巡查最晚结束时刻',
          dataIndex: 'endtime',
          key: 'endtime',
          ellipsis: true,
          scopedSlots: { customRender: 'endtime' },
        },
        // {
        //   title: '巡查时间间隔',
        //   dataIndex: 'intervals',
        //   key: 'intervals',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'intervals' },
        // },
        // {
        //   title: '巡查线路',
        //   dataIndex: 'patrolline',
        //   key: 'patrolline',
        //   ellipsis: true,
        // },
        {
          title: '设置人',
          dataIndex: 'recordman',
          key: 'recordman',
          ellipsis: true,
          width: 100,
        },
        {
          title: '设置时间',
          dataIndex: 'createtime',
          key: 'createtime',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'createtime' },
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData:[],
      queryParams: {
        patroldailytemplateid:'',
        monitorpointname:'',
        monitorpointnum:'',
        patroldailytemplatename:'',
        templatetype:0,
        starttime:'',
        endtime:'',
        patrolline:null,
        intervals:'',
        recordman:'',
        status:'0',
      },
      formRules: {
        patroldailytemplatename: [{required: true, message: '请输入巡查模板名称'}],
        // patrolline: [{required: true, message: '请选择巡查线路'}],
        starttime: [{required: true, message: '请选择开始巡查时刻'}],
        endtime: [{required: true, message: '请选择结束巡查时刻'}],
      },
      templateVisible:false,
      templateType:'detail',
      patrolline:[],
      InspectionPoint:[],
      checkedKeys: [],
      inspectionOptions: [],
      lineVisible:false,
      detailData:{},
      tabsChildKey:0,
      currentKey:0,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  created() {
    this.getTableData(true)
    this.getMachineRoomList()
    this.getMonitorpointName()
  },
  methods:{
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=res.item.length>0?res.item[0].monitorpointnum:""
        }
      })
    },
    getMachineRoomList(){
      getMachineRoomListByCondition({status:0}).then(res=>{
        if(res&&res.returncode==='0'){
          this.InspectionPoint=res.item
          this.inspectionOptions = res.item.map(item => {
            return {
              key: item.machine_room_id+'',
              title: item.machine_room_name
            }
          })
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        status:this.queryParams.status,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEngineerPatrolDailyTemplateListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    initDetail(record){
      this.$nextTick(() => {
        for(let key in this.queryParams) {
          if(key==="starttime"||key==="endtime"){
            this.queryParams[key] = moment("1970-01-01 "+record[key],"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")||'';
          }else if(key==="patrolline"){
            this.patrolline = record[key].toString().split(',')||'';
            this.checkedKeys = record[key].toString().split(',')||'';
          }else{
            this.queryParams[key] = record[key]||'';
          }
        }
      })
    },
    showModal(type){
      if(type==='add'){
        this.templateVisible=true
      }else if(type==='detail'){
        this.templateVisible=true
        this.title="人工巡查线路详情"
      }else if(type==='edit'){
        this.templateVisible=true
        this.title="修改人工巡查线路"
      }
      this.templateType=type
    },
    handleChange(value){
      this.queryParams.patrolline=""
      if(value){
        for(let i = 0;i<value.length;i++){
          this.queryParams.patrolline=this.queryParams.patrolline+value[i]+","
        }
        this.queryParams.patrolline=this.queryParams.patrolline.toString().substring(0,this.queryParams.patrolline.toString().length-1)
      }
    },
    handleOk(){
      this.queryParams.recordman=this.userInfo.username
      this.queryParams.starttime = moment(this.queryParams.starttime).format("HHmmss")
      this.queryParams.endtime = moment(this.queryParams.endtime).format("HHmmss")
      let params = {
        ...this.queryParams
      }
      if(this.templateType==='add'){
        addEngineerPatrolDailyTemplate(params).then(res=>{
          if(res&&res.returncode=='0'){
            this.templateVisible=false
            this.$message.success("操作成功")
            this.getTableData(true)
            this.tabskey='3'
          }else{
            this.$message.error(res.errormsg)
          }
        })
      }else if(this.templateType==='edit'){
        modifyEngineerPatrolDailyTemplate(params).then(res=>{
          if(res&&res.returncode=='0'){
            this.templateVisible=false
            this.$message.success("操作成功")
            this.getTableData(true)
            this.tabskey='3'
          }else{
            this.$message.error(res.errormsg)
          }
        })
      }
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let patroldailytemplateid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'patroldailytemplateid', patroldailytemplateid);
      if(type == 'delete') {
        this.deleteConfirm(patroldailytemplateid, record)
      }else if(type == 'detail'){
        this.showModal(type)
        this.initDetail(record)
      }else if(type == 'edit'){
        this.showModal(type)
        this.initDetail(record)
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.patroldailytemplateid);
      }).catch(()=>{
      });
    },
    delete(patroldailytemplateid) {
      this.showLoading();
      if(patroldailytemplateid) {
        let params = {
          patroldailytemplateid,
          status:1
        };
        modifyEngineerPatrolDailyTemplate(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData(true);
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    rowClick(record) {
      this.currentKey=this.currentKey+1
      this.detailData=record
      this.lineVisible=true
      this.initDetail(record)
    },
    inspectionCheckedChange(targetKeys) {
      this.checkedKeys = targetKeys;
      this.queryParams.patrolline=""
      if(this.checkedKeys){
        for(let i = 0;i<this.checkedKeys.length;i++){
          this.queryParams.patrolline=this.queryParams.patrolline+this.checkedKeys[i]+","
        }
        this.queryParams.patrolline=this.queryParams.patrolline.toString().substring(0,this.queryParams.patrolline.toString().length-1)
      }
    },
    onChange(e){
      this.queryParams.status=e.target.value
      this.tabsChildKey=e.target.value
      this.dataIndex=e.target.value==0?0:1
      this.getTableData(true,this.dataIndex)
    },
  }
}
</script>
<style lang="scss" scoped>
</style>